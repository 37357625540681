import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["individualBtn", "colorRadio", "colorField", "form"]
  static values = {
    hexcolor: String,
  }

  declare readonly colorRadioTargets: Array<HTMLInputElement>
  declare readonly colorFieldTarget: HTMLInputElement
  declare readonly individualBtnTarget: HTMLElement
  declare readonly formTarget: HTMLFormElement
  declare hexcolorValue: string

  async updateColor(event) {
    this.hexcolorValue = event.target.value
  }

  async hexcolorValueChanged(value: string, oldValue: string) {
    for (const target of this.colorRadioTargets) {
      target.checked = target.value === value
    }
    this.colorFieldTarget.value = value

    this.individualBtnTarget.style.setProperty("background", value)

    if (!oldValue || value === oldValue) {
      return
    }

    this.formTarget.requestSubmit()
  }
}
