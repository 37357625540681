import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["avatarInput", "avatarList"]

  declare readonly avatarInputTarget: HTMLInputElement
  declare readonly avatarListTarget: HTMLTemplateElement

  prev() {
    const currentAvatar = this.element.querySelector(":scope > img[data-avatar-id]") as HTMLElement
    const prevAvatar = this.avatarListTarget.content.lastElementChild as HTMLElement
    currentAvatar.after(prevAvatar)
    this.avatarListTarget.content.prepend(currentAvatar)
    this.avatarInputTarget.value = prevAvatar.dataset.avatarId
    if (this.avatarInputTarget.type.toLowerCase() === "hidden") {
      this.avatarInputTarget.dispatchEvent(new Event("input"))
    }
  }

  next() {
    const currentAvatar = this.element.querySelector(":scope > img[data-avatar-id]") as HTMLElement
    const nextAvatar = this.avatarListTarget.content.firstElementChild as HTMLElement
    currentAvatar.after(nextAvatar)
    this.avatarListTarget.content.append(currentAvatar)
    this.avatarInputTarget.value = nextAvatar.dataset.avatarId
    if (this.avatarInputTarget.type.toLowerCase() === "hidden") {
      this.avatarInputTarget.dispatchEvent(new Event("input"))
    }
  }
}
