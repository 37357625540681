import * as ActiveStorage from "@rails/activestorage"

import jQuery from "jquery"
window.$ = window.jQuery = jQuery

import "slick-carousel"

import { Modal } from "bootstrap"
import { loadingOverlay } from "./lib/utils"

ActiveStorage.start()

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./lib/turbo_stream_actions"
import "./controllers"

window.B4M = {
  showModal(element) {
    Modal.getOrCreateInstance(element).show()
  }
}

import ShadowPage from "./lib/ShadowPage"
$(document).ready(function () {
  window.customElements.define("shadow-page", ShadowPage)
});

// Fullscreen loading overlay for Turbo requests
{
  document.addEventListener("turbo:before-fetch-request", (event) => {
    if (Object.hasOwn(event.target.dataset, "loadingOverlay")) {
      loadingOverlay.show(250)
    }
  })
  document.addEventListener("turbo:before-fetch-response", (event) => {
    loadingOverlay.hide()
  })
  document.addEventListener("turbo:fetch-request-error", (event) => {
    loadingOverlay.hide()
  })
}

// Workaround for Bootstrap modal z-index issue with multiple open modals
// Based on https://stackoverflow.com/a/25981907
// TODO: Re-evaluate this when Bootstrap switches to <dialog>-based modals
$(document).on({
  "show.bs.modal": function () {
    var zIndex = 1040 + (10 * $(".modal.show").length);
    $(this).css("z-index", zIndex);
    setTimeout(function() {
      $(".modal-backdrop").not(".modal-stack").css("z-index", zIndex - 1).addClass("modal-stack");
    });
  },
  "hidden.bs.modal": function() {
    if ($(".modal.show").length > 0) {
      // restore the modal-open class to the body element, so that scrolling works
      // properly after de-stacking a modal.
      setTimeout(function() {
        $(document.body).addClass("modal-open");
      });
    }
  }
}, ".modal");
