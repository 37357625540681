import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shippingField"];
  shippingFieldTarget: HTMLElement;

  connect() {
  }

  toggle() {
    const printedCopyCheckbox = this.element as HTMLInputElement;
    const shippingField = this.shippingFieldTarget as HTMLElement; 

    shippingField.classList.toggle("d-none", printedCopyCheckbox.checked)
  }
}
