import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["image"];

  declare readonly imageTargets: HTMLElement[];

  connect() {
    this.initializeSortable();
  }

  getImageIds(): string[] {
    return this.imageTargets.map((image) => image.querySelector("img").id);
  }

  initializeSortable() {
    Sortable.create(this.element, {
      animation: 150,
      handle: ".upload-box",
      onEnd: this.onSortEnd.bind(this) 
    });
  }

  onSortEnd(event: any) {
    const newOrder = this.getImageIds();
    this.updateOrderOnServer(newOrder);
  }

  async updateOrderOnServer(newOrder: string[]): Promise<void> {
    const response = await patch("/book_creation/swap_order_by_drag_drop", {
      body: JSON.stringify({ ordered_image_ids: newOrder }), 
      headers: { "Content-Type": "application/json" }
    });
  }
}
