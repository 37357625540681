import { Controller } from "@hotwired/stimulus"
import { breakpoints, onVisible } from "../lib/utils"
import $ from "jquery"
import { get } from "@rails/request.js"

const sliderBreakpoint = 1400

export default class extends Controller {
  static values = {
    bookSignedId: String,
    prev: { type: String, default: "Previous page" },
    next: { type: String, default: "Next page" },
    initialSlide: { type: String, default: "0" },
    explanationContainer: { type: String, default: null }
  }

  declare readonly bookSignedIdValue: string
  declare prevValue: string
  declare nextValue: string
  declare initialSlideValue: string
  declare explanationContainerValue: string | null

  connect() {
    if (window.innerWidth < sliderBreakpoint) {
      this.element.querySelectorAll(".slide-mobile-hide").forEach(el => el.remove())
    }
    this.element.addEventListener("focusin", this.focusHandler)
    this.element.addEventListener("focusout", this.focusHandler)

    let initialSlideIndex = parseInt(this.initialSlideValue)
    if (isNaN(initialSlideIndex)) {
      initialSlideIndex = Math.max(0, $(this.element).find(".slide").index($(this.initialSlideValue)))
    }

    onVisible(this.element, () => {
      $(this.element).not(".slick-initialized").slick({
        slidesToScroll: 2,
        slidesToShow: 2,
        initialSlide: initialSlideIndex,
        dots: false,
        arrows: true,
        infinite: false,
        prevArrow: `<button type="button" class="slick-arrow slick-prev" title="${this.prevValue}"><span class="arrow-text">${this.prevValue}</span><i class="bi bi-chevron-left"></i></button>`,
        nextArrow: `<button type="button" class="slick-arrow slick-next" title="${this.nextValue}"><span class="arrow-text">${this.nextValue}</span><i class="bi bi-chevron-right"></i></button>`,
        speed: 700,
        waitForAnimate: false,
        responsive: [
          {
            breakpoint: sliderBreakpoint,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1
            }
          }
        ]
      }).on("afterChange", (_event, slick, _currentSlide) => {
        const current_slider = $(slick.$slider).attr("id")

        if (!["book-slider-invite", "book-slider-cover", "book-slider-author"].includes(current_slider)) {
          return
        }

        if (this.explanationContainerValue) {
          this.changeExplanationPage()
        }

        const lastSlideActive = slick.$slides.length && slick.$slides[slick.$slides.length -1].classList.contains("slick-active")
        if (lastSlideActive && current_slider === "book-slider-cover" && window.innerWidth < breakpoints.md) {
          document.querySelector(".btn-holder").scrollIntoView()
        }
      })
    })
  }

  changeExplanationPage() {
    const currentSlideWithType = this.element.querySelector(".slick-current [data-slide-type]") as HTMLElement | null
    if (!currentSlideWithType) {
      return
    }

    const slideType = currentSlideWithType.dataset.slideType
    const explanationEl = document.querySelector(this.explanationContainerValue) as HTMLElement
    explanationEl.dataset.active = slideType
  }

  disconnect() {
    this.element.removeEventListener("focusin", this.focusHandler)
    this.element.removeEventListener("focusout", this.focusHandler)
    $(this.element).filter(".slick-initialized").slick("unslick")
  }

  focusHandler = (event: FocusEvent) => {
    if (!(event.target instanceof Element) || !event.target.classList.contains("response-box")) {
      return
    }

    if (event.type === "focusin") {
      $(this.element).slick("slickSetOption", "swipe", false, false)
    } else if (event.type === "focusout") {
      $(this.element).slick("slickSetOption", "swipe", true, false)
    }
  }

  findSlideIndex(element: HTMLElement) {
    const slideRoot = element.closest("[data-slick-index]") as HTMLElement | null
    if (!slideRoot) {
      throw new Error("Given element is not a child of a slide")
    }

    return parseInt(slideRoot.dataset.slickIndex)
  }

  replaceSlides(startIndex: number, length: number, replacementSlides: Element[]) {
    const currentSlideIndex = $(this.element).slick("slickCurrentSlide") as number

    for (let i = 0; i < length; i++) {
      $(this.element).slick("slickRemove", startIndex)
    }

    // TODO: Replace reverse() with toReversed() once it's widely supported
    for (const slideContent of replacementSlides.reverse()) {
      if (window.innerWidth < sliderBreakpoint && slideContent.classList.contains("slide-mobile-hide")) {
        continue
      }

      // Need to add two extra divs, due to a bug in SlickSlider
      // See https://github.com/kenwheeler/slick/issues/3324
      const slideDiv = document.createElement("div")
      slideDiv.appendChild(document.createElement("div"))
      slideDiv.firstElementChild.appendChild(slideContent)

      // Without this condition, slick slide has problems when adding
      // slides when the slider is empty. Passing startIndex 0 to slickAdd
      // won't work in that case, while ommiting the startIndex does work.
      if (startIndex === 0) {
        $(this.element).slick("slickAdd", slideDiv, true)
      } else {
        $(this.element).slick("slickAdd", slideDiv, startIndex, true)
      }
    }

    $(this.element).slick("slickGoTo", currentSlideIndex, true)
  }

  async reloadPhotoUploadPages(event) {
    if (!event.params.inviteId) {
      throw new Error("Missing inviteId parameter")
    }

    const response = await get("/participant/photo_upload_fragment_new", {
      query: { signed_book_id: this.bookSignedIdValue, signed_invite_id: event.params.inviteId }
    })
    if (!response.ok) {
      return
    }

    const firstPhotoIndex = this.findSlideIndex(this.element.querySelector(".photo-slides"))
    const photoPageCount = this.element.querySelectorAll(".photo-slides").length

    const template = document.createElement("template")
    template.innerHTML = await response.html
    const newSlides = Array.from(template.content.children)

    this.replaceSlides(firstPhotoIndex, photoPageCount, newSlides)
  }

  async reloadEditFullSizeContentPages(event) {
    const response = await get(`/fragments/books/${this.bookSignedIdValue}/edit_full_size_content`)
    if (!response.ok) {
      return
    }

    const editFullSizeContentIndex = this.findSlideIndex(this.element.querySelector("[data-slide-type=\"full-size-content\"]"))

    const template = document.createElement("template")
    template.innerHTML = await response.html
    const newSlides = Array.from(template.content.children)

    this.replaceSlides(editFullSizeContentIndex, 1, newSlides)
  }

  replaceProfilePages(event) {
    const firstProfilePageIndex = this.findSlideIndex(this.element.querySelector(":scope [data-slide-type=\"about-me\"]"))
    const profilePageCount = this.element.querySelectorAll(":scope [data-slide-type=\"about-me\"]").length

    const doc = event.detail as DocumentFragment

    this.replaceSlides(firstProfilePageIndex, profilePageCount, Array.from(doc.children))
  }

  replaceAllPages(event) {
    const firstSlideIndex = this.findSlideIndex(this.element.querySelector(":scope .slick-slide"))
    const slideCount = this.element.querySelectorAll(":scope .slick-slide").length

    const doc = event.detail as DocumentFragment

    this.replaceSlides(firstSlideIndex, slideCount, Array.from(doc.children))
  }
}
