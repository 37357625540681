import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = { uuid: String, modalId: String }
  static targets = ["photoEl", "personalEl"]

  declare uuidValue: string
  declare modalIdValue: string

  declare readonly photoElTarget: HTMLImageElement
  declare readonly personalElTarget: HTMLImageElement

  openModal(event) {
    const avatarEl = this.element.querySelector(":scope img[data-avatar-id]") as HTMLImageElement
    const modalEl = document.getElementById(this.modalIdValue)

    if (this.modalIdValue === "personal-upload-modal") {
      const imgelement = modalEl.querySelector(
        '.img-holder img[data-personal-upload-target="avatarImg"]',
      ) as HTMLImageElement
      if (imgelement) {
        imgelement.src = avatarEl.src
      }
    }

    const modal = Modal.getOrCreateInstance(modalEl)

    const onSave = (event) => {
      if (this.modalIdValue === "personal-upload-modal") {
        this.personalElTarget.src = event.detail.imageUrl
      } else {
        this.photoElTarget.src = event.detail.imageUrl
      }

      patch(`/invites/${this.uuidValue}`, {
        body: { invite: { profile: event.detail.imageId } }
      }).catch((error) => {
        alert("Error uploading file")
      })

      modal.hide()
    }

    const onModalHidden = () => {
      modalEl.removeEventListener("hidden.bs.modal", onModalHidden)
      modalEl.removeEventListener("saveUpload", onSave)
    }

    modalEl.addEventListener("hidden.bs.modal", onModalHidden)
    modalEl.addEventListener("saveUpload", onSave)

    modal.show()
  }
}
