import {Application, ControllerConstructor} from "@hotwired/stimulus"

declare global {
  interface Window {
    Stimulus: {
      storedControllers: Record<string, ControllerConstructor>
    }
  }
}

export default class ShadowPage extends HTMLElement {
  static formAssociated = true

  private formData = new FormData()
  private internals = this.attachInternals()

  constructor() {
    super()

    if (!this.shadowRoot) {
      const template = this.querySelector("template[shadowrootmode]") as HTMLTemplateElement
      if (!template) {
        return
      }

      const mode = template.getAttribute("shadowrootmode") as ShadowRootMode
      const shadowRoot = this.attachShadow({ mode })
      shadowRoot.appendChild(template.content)
      template.remove()
    }

    Array.from(this.shadowRoot.children).forEach(child => {
      if (child instanceof HTMLStyleElement || child instanceof HTMLTemplateElement || child instanceof HTMLScriptElement || child instanceof HTMLLinkElement) {
        return
      }

      const app = Application.start(child)
      for (const storedControllersKey in window.Stimulus.storedControllers) {
        app.register(storedControllersKey, window.Stimulus.storedControllers[storedControllersKey])
      }
    })

    this.shadowRoot.querySelectorAll("input, textarea").forEach((input: HTMLInputElement | HTMLTextAreaElement) => {
      this.formData.set(input.name, input.value)

      input.addEventListener("input", () => {
        this.formData.set(input.name, input.value)
        this.internals.setFormValue(this.formData)
        this.updateValidity()
      })
    })
    this.internals.setFormValue(this.formData)
    this.updateValidity()
  }

  updateValidity() {
    const allValid = Array.from(this.shadowRoot.querySelectorAll("input, textarea, select")).reduce((result, input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) => {
      return result && input.validity.valid
    }, true)

    allValid ? this.internals.setValidity({}) : this.internals.setValidity({ valueMissing: true }, "values missing")
  }

  get form() {
    return this.internals.form
  }

  get validity() {
    return this.internals.validity
  }
}
